import React from 'react';
import { NSCookieBanner } from 'aqlrc';
import { withI18next } from '../lib/withI18n';

class CookieBanner extends NSCookieBanner {
    render() {
        const { t } = this.props;
        const {
            hide, message, style
        } = this.state;
        if (!hide) {
            return (
                <div style={style} className="cookie-banner">
                    <link rel="stylesheet" href="/static/css/cookie-banner.css" />
                    <p className="cookie-message" dangerouslySetInnerHTML={{ __html: message }} />
                    <div className="cookie-buttons">
                        <button type="button" className="cookie-button allow-button" onClick={() => this.deny()}>
                            {t('common:buttonCookie')}
                        </button>
                    </div>
                </div>
            );
        }
        return '';
    }
}

export default withI18next([])(CookieBanner);