import React from 'react';
import { NSContext } from 'aqlrc';
import { Link } from '../routes';

/**
 * Menu - Affiche le bloc du menu correspondant au code passé en prop (ns-code)
 * @return {React.Component}
 */

class Menu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            view : []
        };
    }

    routeChangeListener = () => {
        if (document.querySelector('.nav')) {
            document.querySelector('.nav').classList.remove('active');
        }
    }

    componentDidMount() {
        if (document.querySelector('.nav-trigger')) {
            document.querySelector('.nav-trigger').onclick = () => {
                document.querySelector('.nav').classList.toggle('active');
            };

            window.addEventListener('routeChange', this.routeChangeListener);
        }
    }

    displayMenu = (id) => {
        const { view } = this.state;
        const index = view.findIndex((e) => e === id);
        if (index === -1) {
            view.push(id);
        }
        this.setState({ view });
    }

    hideMenu = (id) => {
        const { view } = this.state;
        const index = view.findIndex((e) => e === id);
        if (index > -1) {
            view.splice(index, 1);
        }
        this.setState({ view });
    }

    render() {
        const { view } = this.state;
        const { props } = this.context;
        if (!props) { return null; }
        const { routerLang } = props;
        const nsMenu = props[`nsMenu_${this.props['ns-code']}`];
        return (
            <nav className="nav">
                <ul>
                    { 
                        nsMenu.children.map((section) => {
                            const liClass = [];
                            if (section.children && section.children.length > 0)
                                liClass.push('has-dropdown');
                            if (view.find((v) => v === section._id))
                                liClass.push('active');
                            return (
                                <li 
                                    key={section._id} 
                                    className={liClass.join(' ')}
                                    onMouseEnter={() => this.displayMenu(section._id)}
                                    onMouseLeave={() => this.hideMenu(section._id)}
                                >
                                    <Link route="staticI18n" params={{ _slug: section.pageSlug, lang: routerLang }}>
                                        <a className={section.children && section.children.length > 0 ? 'has-dropdown-link' : undefined}>{section.name}</a>
                                    </Link>
                                    { 
                                        section.children && section.children.length > 0 ? 
                                            <div className="nav-dropdown">
                                                <ul>
                                                    {
                                                        section.children.map((section2) => {
                                                            return (
                                                                <li key={section2._id}>
                                                                    {section2.extraText2 && <i className={section2.extraText2}></i>}

                                                                    {
                                                                        section2.extraText ? (
                                                                            <a href={section2.extraText} target="_blank">{section2.name}</a>
                                                                        ) : (
                                                                            <Link route="staticI18n" params={{ _slug: section2.pageSlug, lang: routerLang }}>
                                                                                <a>{section2.name}</a>
                                                                            </Link>
                                                                        )
                                                                    }
                                                                    
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div> : null
                                    }
                                </li>
                            )
                        })
                    }
                </ul>
            </nav>
        );
    }

    componentWillUnmount() {
        window.removeEventListener('routeChange', this.routeChangeListener);
    }

    static contextType = NSContext;
}

export default Menu;
