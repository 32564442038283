
import moment from 'moment';
import { NSBlogArticles } from 'aqlrc';
import { Link } from '../routes';
import { withI18next } from '../lib/withI18n';

class BlogArticles extends NSBlogArticles {
    render() {
        const { t } = this.props;
        const { props } = this.context;
        if (!props) { return null; }
        const { lang, routerLang, nsBlogArticles } = props;
        const url = typeof window !== 'undefined' ? window.location.origin : '';
        return (
            nsBlogArticles.length ? nsBlogArticles.map((article) => (
                <section className="section-contact section-contact--main">
                    <div className="shell">
                        <div key={article._id}>
                            <h2 className="section__title decoration-line-mixed">
                                <Link route="blogArticle" params={{ lang: routerLang, _slug: article.slug[lang] }}>
                                    <a>{article.title}</a>
                                </Link>
                            </h2>

                            <div itemProp="image" itemScope="" itemType="http://schema.org/ImageObject">
                                <meta itemProp="url" content={`${url}/${article.img}`} />
                            </div>

                            <div className="section__body">
                                <div className="article-date" itemProp="datePublished" content={moment(article.createdAt).format('DD/MM/YYYY - HH[h]mm')} style={{ marginBottom: '20px' }}>
                                    {moment(article.createdAt).format('DD/MM/YYYY - HH[h]mm')}
                                </div>
                                <div style={{ display: 'flex' }}>
                                    {article.img && <img src={`/images/blog/200x200/${article._id}/${article.slug[lang]}${article.extension}`} alt={article.title} style={{ marginRight: '20px' }} />}
                                    <div>
                                        <div 
                                            itemProp="articleBody"
                                            className="ns-article-content"
                                            dangerouslySetInnerHTML={{ __html: `${article.content ? `${article.content.resume} ...` : null}` }}
                                        />
                                        <div>
                                            <Link route="blogArticle" params={{ lang: routerLang, _slug: article.slug[lang] }}>
                                                <a>[{t('common:readNext')}]</a>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )) : <p>Aucun article</p>
        );
    }
}

export default withI18next([])(BlogArticles);