import React from 'react';
import { _NSconfig } from 'aqlrc'; // Import depuis AQT de la config par défaut
import BlockSlider from './BlockSlider';
import BlogArticles from './BlogArticles';
import Disconnect from './Disconnect';
import Login from './Login';
import Menu from './Menu';
import Newsletter from './Newsletter';
import SwitchMenuMobile from './SwitchMenuMobile';

// On surcharge la config par défaut de AQT si besoin
// A noter que <Link> et <CMS> sont déjà gérés directement dans le composant CMS, il faut utiliser respectivement "ns-link" et "ns-cms"
// A garder dans l'ordre alphabétique en fonction du nom du composant SVP
export default {
    ..._NSconfig,
    'ns-block-slider'       : <BlockSlider />,
    'ns-blog-articles'      : <BlogArticles />,
    'ns-disconnect'         : <Disconnect />,
    'ns-login'              : <Login />,
    'ns-menu'               : <Menu />,
    'ns-newsletter'         : <Newsletter />,
    'ns-switch-menu-mobile' : <SwitchMenuMobile />
};
